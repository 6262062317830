import React from "react";
import { LeftNavigation, NavigationItem, NavigationHeader, PageContainer as OnePageContainer, ContentContainer } from "one-ui-fabric/dist";
import { NavLink, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import {
	SettingsComponent,
	UsersComponent,
	HomeComponent,
	PageNotFoundComponent,
	LookupListsComponent,
	UsageComponent,
	AudiencesComponent,
	JobsComponent,
} from "../../routes";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { TenantSettingsComponent } from "../../routes/settingsComponent/tenantSettingsComponent/TenantSettingsComponent";
import { TeamsSettingsComponent } from "../../routes/settingsComponent/teamsSettingsComponent/TeamsSettingsComponent";
import { YouSettingsComponent } from "../../routes/settingsComponent/youSettingsComponent/YouSettingsComponent";
import { EventSettingsComponent } from "../../routes/settingsComponent/eventSettingsComponent/EventSettingsComponent";
import { ReleasesComponent } from "../../routes/releasesComponent/ReleasesComponent";
import PrivateRoute from "../../../common/privateRoute/PrivateRoute";
import { UnauthorizedComponent } from "../../routes/unauthorizedComponent/unauthorized";
import { TaxonomyComponent } from "../../routes/taxonomyComponent/TaxonomyComponent";
import { UsageDownloadComponent } from "../../routes/usageComponent/usageDownloadComponent/UsageDownloadComponent";
import { PermissionCodes, hasAccess } from "@one/core";
import { RolesComponent } from "../../routes/rolesComponent/RolesComponent";
import { EditMailTemplateComponent } from "../../routes/mailTemplatesComponent/EditMailTemplateComponent";
import { RolePermissionsOverviewComponent } from "../../routes/rolePermissionsOverviewComponent/RolePermissionsOverviewComponent";
import { CopilotSettingsComponent } from "../../routes/settingsComponent/copilotSettingsComponent/CopilotSettingsComponent";

const PageContainerComponent = (props: WrappedComponentProps) => {
	const { intl } = props;
	const location = useSelector((state: IApplicationState) => state.router.location);
	const userSlice = useSelector((state: IApplicationState) => state.currentUserSlice);

	if (Object.keys(userSlice.user).length !== 0 && userSlice.user.constructor === Object) {
		return (
			<OnePageContainer>
				<LeftNavigation>
					<NavigationHeader
						headerText={intl.formatMessage({
							id: "general.navigationHeader",
							defaultMessage: "Instellingen",
						})}
					/>
					<NavLink to="/home" activeClassName="navigation-item__active">
						<NavigationItem
							iconName="Home"
							title={intl.formatMessage({
								id: "home.navigation.item",
								defaultMessage: "Start",
							})}
						/>
					</NavLink>

					{hasAccess(userSlice.user as any, [PermissionCodes.ManageLookupList]) && (
						<NavLink to="/lookUpLists" activeClassName="navigation-item__active">
							<NavigationItem
								iconName="BulletedList"
								title={intl.formatMessage({
									id: "lookup.navigation.item",
									defaultMessage: "Opzoeklijsten",
								})}
							/>
						</NavLink>
					)}
					{hasAccess(userSlice.user as any, [PermissionCodes.ManageUsers]) && (
						<NavLink to="/users" activeClassName="navigation-item__active">
							<NavigationItem
								iconName="Group"
								title={intl.formatMessage({
									id: "users.navigation.item",
									defaultMessage: "Gebruikers",
								})}
							/>
						</NavLink>
					)}
					{hasAccess(userSlice.user as any, [PermissionCodes.ManageUsers]) && (
						<NavLink to="/roles" activeClassName="navigation-item__active">
							<NavigationItem
								iconName="Permissions"
								title={intl.formatMessage({
									id: "roles.navigation.item",
									defaultMessage: "Rollen",
								})}
							/>
						</NavLink>
					)}
					{hasAccess(userSlice.user as any, [PermissionCodes.ManageAudiences]) && (
						<NavLink to="/audiences" activeClassName="navigation-item__active">
							<NavigationItem
								iconName="People"
								title={intl.formatMessage({
									id: "audiences.navigation.item",
									defaultMessage: "Doelgroepen",
								})}
							/>
						</NavLink>
					)}

					{hasAccess(userSlice.user as any, [PermissionCodes.ManageTaxonomy]) && (
						<NavLink to="/taxonomy" activeClassName="navigation-item__active">
							<NavigationItem
								iconName="BulletedTreeList"
								title={intl.formatMessage({
									id: "taxonomy.navigation.item",
									defaultMessage: "Taxonomie",
								})}
							/>
						</NavLink>
					)}

					{hasAccess(userSlice.user as any, [PermissionCodes.ManageMailTemplates]) && (
						<NavLink to="/mail-templates" activeClassName="navigation-item__active">
							<NavigationItem
								iconName="Mail"
								title={intl.formatMessage({
									id: "mailTemplates.navigation.item",
								})}
							/>
						</NavLink>
					)}

					{hasAccess(userSlice.user as any, [
						PermissionCodes.ManageTeamsSettings,
						PermissionCodes.ManageEventsSettings,
						PermissionCodes.ManageTenantSettings,
						PermissionCodes.ManageOneForYouSettings,
						PermissionCodes.ManageApplicationSettings,
					]) && (
						<NavLink to="/settings" activeClassName="navigation-item__active">
							<NavigationItem
								iconName="Settings"
								title={intl.formatMessage({
									id: "settings.general",
									defaultMessage: "Instellingen",
								})}
							/>
						</NavLink>
					)}

					{hasAccess(userSlice.user as any, [
						PermissionCodes.ManageFeedback_OneForMedia,
						PermissionCodes.ManageFeedback_OneQualityManagement,
						PermissionCodes.ViewUsageReporting,
						PermissionCodes.ViewApplicationReleases,
						PermissionCodes.ManageJobs,
					]) && (
						<>
							<NavigationHeader
								headerText={intl.formatMessage({
									id: "general.overview",
									defaultMessage: "Overzicht",
								})}
							/>

							{hasAccess(userSlice.user as any, [PermissionCodes.ViewUsageReporting]) && (
								<NavLink to="/usage" activeClassName="navigation-item__active">
									<NavigationItem
										iconName="AnalyticsView"
										title={intl.formatMessage({
											id: "usage.navigation.item",
											defaultMessage: "Gebruikersstatistieken",
										})}
									/>
								</NavLink>
							)}

							{hasAccess(userSlice.user as any, [PermissionCodes.ViewApplicationReleases]) && (
								<NavLink to="/releases" activeClassName="navigation-item__active">
									<NavigationItem
										iconName="ProductRelease"
										title={intl.formatMessage({
											id: "releases.navigationItem.title",
											defaultMessage: "Release informatie",
										})}
									/>
								</NavLink>
							)}

							{/* {(hasPermission(userSlice.user, [
								PermissionCodes.ManageFeedback_OneForMedia,
								PermissionCodes.ManageFeedback_OneQualityManagement,
							]) ||
								userIsAdmin) && (
								<NavLink to="/feedback" activeClassName="navigation-item__active">
									<NavigationItem
										iconName="Feedback"
										title={intl.formatMessage({
											id: "feedback.navigationItem.title",
											defaultMessage: "Feedback",
										})}
									/>
								</NavLink>
							)} */}
						</>
					)}

					{hasAccess(userSlice.user as any, [PermissionCodes.ManageJobs, PermissionCodes.ViewRolePermissions]) && (
						<>
							<NavigationHeader
								headerText={intl.formatMessage({
									id: "general.superadmin",
									defaultMessage: "Overzicht",
								})}
							/>

							{hasAccess(userSlice.user as any, [PermissionCodes.ManageJobs]) && (
								<NavLink to="/jobs" activeClassName="navigation-item__active">
									<NavigationItem
										iconName="ScheduleEventAction"
										title={intl.formatMessage({
											id: "jobs.navigationItem.title",
											defaultMessage: "Jobs",
										})}
									/>
								</NavLink>
							)}
							{hasAccess(userSlice.user as any, [PermissionCodes.ViewRolePermissions]) && (
								<NavLink to="/rolePermissions" activeClassName="navigation-item__active">
									<NavigationItem
										iconName="TableLink"
										title={intl.formatMessage({
											id: "rolePermissions.navigationItem.title",
										})}
									/>
								</NavLink>
							)}
						</>
					)}
				</LeftNavigation>
				<ContentContainer>
					<section className="content-container-padding">
						<Switch location={location}>
							<Route exact path="/home" component={HomeComponent} />

							<PrivateRoute
								exact
								path="/lookUpLists"
								component={LookupListsComponent}
								authorizeFunction={(currentUser) => hasAccess(currentUser, [PermissionCodes.ManageLookupList])}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>

							<PrivateRoute
								exact
								path="/users"
								component={UsersComponent}
								authorizeFunction={(currentUser) => hasAccess(currentUser, [PermissionCodes.ManageUsers])}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>

							<PrivateRoute
								exact
								path="/roles"
								component={RolesComponent}
								authorizeFunction={(currentUser) => hasAccess(currentUser, [PermissionCodes.ManageUsers])}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>
							<PrivateRoute
								exact
								path="/audiences"
								component={AudiencesComponent}
								authorizeFunction={(currentUser) => hasAccess(currentUser, [PermissionCodes.ManageAudiences])}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>
							<PrivateRoute
								exact
								path="/taxonomy"
								component={TaxonomyComponent}
								authorizeFunction={(currentUser) => hasAccess(currentUser, [PermissionCodes.ManageTaxonomy])}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>
							<PrivateRoute
								exact
								path="/mail-templates"
								component={EditMailTemplateComponent}
								authorizeFunction={(currentUser) => hasAccess(currentUser, [PermissionCodes.ManageMailTemplates])}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>

							<PrivateRoute
								exact
								path="/usage"
								component={UsageComponent}
								authorizeFunction={(currentUser) => hasAccess(currentUser, [PermissionCodes.ViewUsageReporting])}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>
							<PrivateRoute
								exact
								path="/usage/download"
								component={UsageDownloadComponent}
								authorizeFunction={(currentUser) => hasAccess(currentUser, [PermissionCodes.ViewUsageReporting])}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>

							<PrivateRoute
								exact
								path="/releases"
								component={ReleasesComponent}
								authorizeFunction={(currentUser) => hasAccess(currentUser, [PermissionCodes.ViewApplicationReleases])}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>

							<PrivateRoute
								exact
								path="/jobs"
								component={JobsComponent}
								authorizeFunction={(currentUser) => hasAccess(currentUser, [PermissionCodes.ManageJobs])}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>

							<PrivateRoute
								exact
								path="/rolePermissions"
								component={RolePermissionsOverviewComponent}
								authorizeFunction={(currentUser) => hasAccess(currentUser, [PermissionCodes.ViewRolePermissions])}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>

							{/* End of page routes */}
							<PrivateRoute
								exact
								path="/settings"
								component={SettingsComponent}
								authorizeFunction={(currentUser) =>
									hasAccess(currentUser, [
										PermissionCodes.ManageTeamsSettings,
										PermissionCodes.ManageEventsSettings,
										PermissionCodes.ManageTenantSettings,
										PermissionCodes.ManageOneForYouSettings,
										PermissionCodes.ManageApplicationSettings,
									])
								}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>
							<PrivateRoute
								exact
								path="/settings/tenantSettings"
								component={TenantSettingsComponent}
								authorizeFunction={(currentUser) => hasAccess(currentUser, [PermissionCodes.ManageTenantSettings])}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>
							<PrivateRoute
								exact
								path="/settings/teamsSettings"
								component={TeamsSettingsComponent}
								authorizeFunction={(currentUser) =>
									hasAccess(currentUser, [PermissionCodes.ManageApplicationSettings, PermissionCodes.ManageTeamsSettings])
								}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>
							<PrivateRoute
								exact
								path="/settings/copilotSettings"
								component={CopilotSettingsComponent}
								authorizeFunction={(currentUser) =>
									hasAccess(currentUser, [PermissionCodes.ManageApplicationSettings])
								}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>
							<PrivateRoute
								exact
								path="/settings/eventsSettings"
								component={EventSettingsComponent}
								authorizeFunction={(currentUser) => hasAccess(currentUser, [PermissionCodes.ManageEventsSettings])}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>
							<PrivateRoute
								exact
								path="/settings/youSettings"
								component={YouSettingsComponent}
								authorizeFunction={(currentUser) => hasAccess(currentUser, [PermissionCodes.ManageOneForYouSettings])}
								redirectTo="/unauthorized"
								currentUser={userSlice.user}
							/>

							<Route exact path="/unauthorized" component={UnauthorizedComponent} />
							<Route component={PageNotFoundComponent} />
						</Switch>
					</section>
				</ContentContainer>
			</OnePageContainer>
		);
	} else {
		return null;
	}
};

export const PageContainer = injectIntl(PageContainerComponent);
