import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { SocialActionTypes, SocialState } from "./types";
import { adalOneApiFetch } from "../../adalConfig";
import { ISocialConnectionResponse } from "../../models/responseModels/social/ISocialConnectionResponse";
import { INewSocialConnection } from "../../models/interfaces/INewSocialConnection";

// --== RESET ==--
export const resetShouldClosePanel = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: SocialActionTypes.RESET_SHOULD_CLOSE_PANEL,
		});
	};
};
export const resetError = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: SocialActionTypes.RESET_ERROR,
		});
	};
};
export const resetSuccess = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: SocialActionTypes.RESET_SUCCESS,
		});
	};
};

// --== Fetch Connection ==--
export const fetchConnections = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchConnectionsRequest());
	};
};

const fetchConnectionsRequest: ActionCreator<ThunkAction<Promise<any>, SocialState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchConnectionsStarted());
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/social/connections`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchConnectionsFailure(response.statusText));
					return;
				}

				const result: ISocialConnectionResponse[] = await response.json();

				dispatch(fetchConnectionsSuccess(result));
			}
		} catch (e) {
			dispatch(fetchConnectionsFailure("error"));
		}
	};
};

const fetchConnectionsStarted = () => ({
	type: SocialActionTypes.FETCH_CONNECTIONS_STARTED,
});

const fetchConnectionsSuccess = (connections: ISocialConnectionResponse[]) => ({
	type: SocialActionTypes.FETCH_CONNECTIONS_SUCCESS,
	payload: {
		connections: connections,
	},
});

const fetchConnectionsFailure = (errorMessage: string | object) => ({
	type: SocialActionTypes.FETCH_CONNECTIONS_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Add Connection ==--
export const addConnection = (newConnection: INewSocialConnection) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(addConnectionRequest(newConnection));
	};
};

const addConnectionRequest: ActionCreator<ThunkAction<Promise<any>, SocialState, null, any>> = (newConnection: INewSocialConnection) => {
	return async (dispatch: Dispatch) => {
		dispatch(addConnectionStarted());
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/social/connections`, {
				method: "POST",
				body: JSON.stringify(newConnection),
				headers: {
					"Content-Type": "application/json",
				},
			});

			// If the reponse code is not positive, throw an error.
			if (response.status !== 201) {
				dispatch(addConnectionFailure(response.statusText));
				return;
			}

			const result: ISocialConnectionResponse[] = await response.json();

			dispatch(addConnectionSuccess(result));
		} catch (e) {
			dispatch(addConnectionFailure("error"));
		}
	};
};

const addConnectionStarted = () => ({
	type: SocialActionTypes.ADD_CONNECTION_STARTED,
});

const addConnectionSuccess = (newConnection) => ({
	type: SocialActionTypes.ADD_CONNECTION_SUCCESS,

	payload: {
		newConnection: newConnection,
	},
});

const addConnectionFailure = (errorMessage: string | object) => ({
	type: SocialActionTypes.ADD_CONNECTION_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Fetch Connection ==--
export const deleteConnection = (connection: ISocialConnectionResponse) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(deleteConnectionRequest(connection));
	};
};

const deleteConnectionRequest: ActionCreator<ThunkAction<Promise<any>, SocialState, null, any>> = (
	connection: ISocialConnectionResponse
) => {
	return async (dispatch: Dispatch) => {
		dispatch(deleteConnectionStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/social/connections/${connection.id}`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(deleteConnectionFailure(response.statusText));
					return;
				}

				// const result: IConnectionResponse = await response.json();

				dispatch(deleteConnectionSuccess(connection));
			}
		} catch (e) {
			dispatch(deleteConnectionFailure("error"));
		}
	};
};

const deleteConnectionStarted = () => ({
	type: SocialActionTypes.DELETE_CONNECTION_STARTED,
});

const deleteConnectionSuccess = (connectionToDelete: ISocialConnectionResponse) => ({
	type: SocialActionTypes.DELETE_CONNECTION_SUCCESS,
	payload: {
		connectionToDelete,
	},
});

const deleteConnectionFailure = (errorMessage: string | object) => ({
	type: SocialActionTypes.DELETE_CONNECTION_FAILURE,
	payload: {
		errorMessage,
	},
});
