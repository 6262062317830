import { SocialActionTypes, SocialState } from "./types";
import { ErrorTypes } from "../../models/enums/ErrorTypes";
import { SuccessTypes } from "../../models/enums/SuccessTypes";

const initialState: SocialState = {
	connections: [],

	isLoading: false,
	isCreating: false,

	shouldClosePanel: false,

	success: {
		type: SuccessTypes.None,
	},
	error: {
		type: ErrorTypes.None,
		message: "",
	},
};

export function socialReducer(state = initialState, action: { type: SocialActionTypes; payload: any }): SocialState {
	switch (action.type) {
		// --== Fetch Connections ==--
		case SocialActionTypes.FETCH_CONNECTIONS_STARTED:
			return {
				...state,
				isLoading: true,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case SocialActionTypes.FETCH_CONNECTIONS_SUCCESS:
			return {
				...state,
				connections: action.payload.connections,
				isLoading: false,
			};
		case SocialActionTypes.FETCH_CONNECTIONS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: {
					type: ErrorTypes.OnInitialDataLoad,
					message: action.payload.errorMessage,
				},
			};

		// --== Add Connection ==--
		case SocialActionTypes.ADD_CONNECTION_STARTED:
			return {
				...state,
				isCreating: true,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case SocialActionTypes.ADD_CONNECTION_SUCCESS:
			const newConnections = [...state.connections];
			newConnections.push(action.payload.newConnection);
			return {
				...state,
				connections: newConnections,
				isCreating: false,
				shouldClosePanel: true,
				success: {
					type: SuccessTypes.OnCreate,
				},
			};
		case SocialActionTypes.ADD_CONNECTION_FAILURE:
			return {
				...state,
				isCreating: false,
				error: {
					type: ErrorTypes.OnCreate,
					message: action.payload.errorMessage,
				},
			};

		// --== Delete Connection ==--
		case SocialActionTypes.DELETE_CONNECTION_STARTED:
			return {
				...state,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case SocialActionTypes.DELETE_CONNECTION_SUCCESS:
			const newConnectionsAfterDelete = [...state.connections].filter(
				(conn) => conn.id !== action.payload.connectionToDelete.id
			);
			return {
				...state,
				connections: newConnectionsAfterDelete,
				shouldClosePanel: true,
				success: {
					type: SuccessTypes.OnDelete,
				},
			};
		case SocialActionTypes.DELETE_CONNECTION_FAILURE:
			return {
				...state,
				error: {
					type: ErrorTypes.OnCreate,
					message: action.payload.errorMessage,
				},
			};
		case SocialActionTypes.RESET_SHOULD_CLOSE_PANEL:
			return {
				...state,
				shouldClosePanel: false,
			};
		case SocialActionTypes.RESET_ERROR:
			return {
				...state,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case SocialActionTypes.RESET_SUCCESS:
			return {
				...state,
				success: {
					type: SuccessTypes.None,
				},
			};

		default:
			return state;
	}
}
