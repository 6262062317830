// Framework & Third-party
import * as React from "react";
import { useState, useEffect } from "react";
import { Panel, Icon, PanelType, PrimaryButton} from "@fluentui/react";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";

export interface IPrivacyPanelProps {
	isOpen: boolean;
	redirectionLink: string | undefined;
	onDismiss: () => void;
	OpenErrorPanel: () => void;
}

const PrivacyPolicyPanel = (props: WrappedComponentProps & IPrivacyPanelProps) => {
	const { redirectionLink, intl } = props;

	return (
		<div>
			<Panel
				className="one-panel privacyPanel"
				isOpen={props.isOpen}
				type={PanelType.largeFixed}
				onDismiss={props.onDismiss}
				closeButtonAriaLabel="Close"
				styles={{
					content: {
						fontSize: '20px',
					},
				}}
			>
				<section className="one-panel__panel-header">
					<div className="one-panel__panel-header--flex">
						<h1>
							<FormattedMessage
								id="settings.you.insta.privacy.panel.header"
								defaultMessage="Privacy statement"
							/>
						</h1>

						<div className="one-panel__panel-close" onClick={props.onDismiss}>
							<Icon iconName="ChromeClose" />
						</div>
					</div>
				</section>
				<section className="one-panel__panel-content">
					<div className="readonly-field">
						<div id="privacy-statement-nl">
                            <h1>One for You - Privacy Statement (NL)</h1>
                            <p>ilionx, gevestigd aan Van Deventerlaan 121 3528 AG Utrecht, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.</p>
                            <p>Contactgegevens:</p>
                            <address>
                                ilionx<br />
                                Van Deventerlaan 121 <br />
                                3528 AG Utrecht<br />
                                (+31) 088 059 05 00
                                https://www.ilionx.com
                            </address>
                            <br />
                            <h2>Persoonsgegevens die wij verwerken</h2>
                            <p>ilionx verwerkt uw persoonsgegevens doordat u gebruik maakt van One for You of omdat u deze zelf aan ons verstrekt. Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:</p>
                            <ul>
                                <li>Gebruikersprofiel bestaande uit weergavenaam, gebruikersnaam, e-mailadres, datum waarop u jarig bent en de datum waarop u in dienst bent gekomen.</li>
                                <li>Instagram posts van de Instagram-accounts welke u koppelt aan One for You.</li>
                            </ul>
                            <br />
                            <h2>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h2>
                            <br />
                            <p>Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via oneilionx@ilionx.com, dan verwijderen wij deze informatie.</p>
                            <br />
                            <h2>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h2>
                            <p>ilionx verwerkt uw persoonsgegevens voor de volgende doelen:</p>
                            <ul>
                                <li>U de mogelijkheid te bieden om gebruik te maken van One for You.</li>
                                <li>U de mogelijkheid te beiden uw via een One for You webpart, Instagram posts te tonen op uw intranet site.</li>
                            </ul>
                            <br />
                            <h2>Hoe lang we persoonsgegevens bewaren</h2>
                            <p>ilionx bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verwerkt. Wij hanteren de volgende bewaartermijnen voor de volgende (categorie�n) van persoonsgegevens: </p>
                            <ul>
                                <li>Uw gebruikersprofiel wordt bewaard zolang uw organisatie One for You afneemt of tot het moment dat uw organisatie account (Azure Active Directory) verwijderd of gedeactiveerd is.</li>
                                <li>Instagram posts worden bewaard zolang uw organisatie One for You afneemt, tot het moment waarop u handmatig de One for You - Instagram connectie verwijderd of wanneer u wij vanuit Instagram een "Deauthorization" of "Data Deletion Request" ontvangen.</li>
                            </ul>
                            <br />
                            <h2>Delen van persoonsgegevens met derden</h2>
                            <p>ilionx verstrekt geen persoonsgegevens aan derden.</p>
                            <br />
                            <h2>Cookies, of vergelijkbare technieken, die wij gebruiken</h2>
                            <p>ilionx gebruikt alleen technische en functionele cookies. En analytische cookies die geen inbreuk maken op uw privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op uw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en uw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld uw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.</p>
                            <br />
                            <h2>Gegevens inzien, aanpassen of verwijderen</h2>
                            <p>U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door ilionx en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen. U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar oneilionx@ilionx.com. Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek. ilionx wil u er tevens op wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</p>
                            <br />
                            <h2>Hoe wij persoonsgegevens beveiligen</h2>
                            <p>ilionx neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via oneilionx@ilionx.com</p>
                        </div>
                        <div id="privacy-statement-en">
                            <h1>One for You - Privacy Statement (EN)</h1>
                            <p>ilionx, located at Van Deventerlaan 121 3528 AG Utrecht, is responsible for the processing of personal data as shown in this privacy statement.</p>
                            <p>Contact details:</p>
                            <address>
                                ilionx<br />
                                Van Deventerlaan 121 <br />
                                3528 AG Utrecht<br />
                                (+31) 088 059 05 00<br />
                                https://www.ilionx.com
                            </address>
                            <br />
                            <h2>Personal data that we process</h2>
                            <p>ilionx processes your personal data because you use One for You or because you provide it to us yourself. Below you will find an overview of the personal data we process:</p>
                            <ul>
                                <li>User profile consisting of display name, username, email address, date of your birthday and date of employment.</li>
                                <li>Instagram posts from the Instagram accounts that you link to One for You.</li>
                            </ul>
                            <br />
                            <h2>Special and/or sensitive personal data that we process</h2>
                            <p>Our website and/or service does not intend to collect data about website visitors who are younger than 16 years of age. Unless they have permission from parents or guardians. However, we cannot check whether a visitor is older than 16. We therefore recommend that parents be involved in the online activities of their children, in order to prevent data about children from being collected without parental consent. If you are convinced that we have collected personal information about a minor without this consent, please contact us at oneilionx@ilionx.com and we will delete this information.</p>
                            <br />
                            <h2>For what purpose and on what basis do we process personal data</h2>
                            <p>ilionx processes your personal data for the following purposes:</p>
                            <ul>
                                <li>To offer you the opportunity to use One for You.</li>
                                <li>You have the option to show your Instagram posts on your intranet site via a One for You web part.</li>
                            </ul>
                            <br />
                            <h2>How long do we keep personal data</h2>
                            <p>ilionx does not store your personal data longer than is strictly necessary to achieve the purposes for which your data is processed. We use the following retention periods for the following (categories) of personal data: </p>
                            <ul>
                                <li>Your user profile will be retained for as long as your organization subscribes to One for You or until your organization account (Azure Active Directory) is deleted or deactivated.</li>
                                <li>Instagram posts are retained for as long as your organization subscribes to One for You, until you manually delete the One for You - Instagram connection or when we receive a "Deauthorization" or "Data Deletion Request" from Instagram.</li>
                            </ul>
                            <br />
                            <h2>Sharing personal data with third parties</h2>
                            <p>ilionx does not share or provide personal data to third parties.</p>
                            <br />
                            <h2>Cookies, or similar techniques, that we use</h2>
                            <p>ilionx only uses technical and functional cookies. And analytical cookies that do not infringe your privacy. A cookie is a small text file that is stored on your computer, tablet or smartphone when you first visit this website. The cookies we use are necessary for the technical operation of the website and your ease of use. They ensure that the website works properly and, for example, remember your preference settings. We can also optimize our website with this. You can unsubscribe from cookies by setting your internet browser so that it no longer stores cookies. In addition, you can also delete all information previously stored via your browser settings.</p>
                            <br />
                            <h2>View, adjust or delete data</h2>
                            <p>You have the right to view, correct or delete your personal data. In addition, you have the right to withdraw your consent to data processing or to object to the processing of your personal data by ilionx and you have the right to data portability. This means that you can submit a request to us to send the personal data we have about you in a computer file to you or another organization mentioned by you. You can send a request for access, correction, deletion, data transfer of your personal data or request for withdrawal of your consent or objection to the processing of your personal data to oneilionx@ilionx.com. To ensure that the request for access has been made by you, we ask you to enclose a copy of your proof of identity with the request. Make your passport photo, MRZ (machine readable zone, the strip with numbers at the bottom of the passport), passport number and Citizen Service Number (BSN) black in this copy. This is to protect your privacy. We will respond to your request as quickly as possible, but within four weeks. ilionx would also like to point out that you have the option to file a complaint with the national supervisory authority, the Dutch Data Protection Authority. This can be done via the following link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</p>
                            <br />
                            <h2>How we protect personal data</h2>
                            <p>ilionx takes the protection of your data seriously and takes appropriate measures to prevent misuse, loss, unauthorized access, unwanted disclosure and unauthorized modification. If you have the impression that your data is not properly secured or there are indications of misuse, please contact our customer service or via oneilionx@ilionx.com</p>
                        </div>
					</div>
				</section>
				<section style={{ display: 'flex', justifyContent: 'center' }}>
					<PrimaryButton
						iconProps={{ iconName: "Accept" }}
						text={intl.formatMessage({
							id: "settings.you.privacy.agree",
							defaultMessage: "Acepteren",
						})}
						href={redirectionLink}
					/>
					<PrimaryButton
						iconProps={{ iconName: "ChromeClose" }}
						text={intl.formatMessage({
							id: "settings.you.privacy.disagree",
							defaultMessage: "Weigeren",
						})}
						onClick={props.OpenErrorPanel}
					/>
				</section>
			</Panel>
		</div>

	);
};

export const PrivacyPolicyPanelComponent = injectIntl(PrivacyPolicyPanel);
