// Framework & Third-pary
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import Loader from "react-loader-spinner";
import {
	PrimaryButton,
	DetailsList,
	IColumn,
	SelectionMode,
	MessageBarType,
	MessageBar,
	MessageBarButton,
	Icon,
	IDetailsListProps,
	IDetailsRowStyles,
	DetailsRow,
	TextField,
} from "@fluentui/react";
import { RouteComponentProps, withRouter } from "react-router-dom";

// Images
import instagramImage from "../../../../assets/images/instagram-logo-80.png";
import topdeskImage from "../../../../assets/images/topdesk-logo-80.png";

// Models
import { SuccessTypes, ErrorTypes } from "../../../../models/enums";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { ISocialConnectionResponse } from "../../../../models/responseModels/social/ISocialConnectionResponse";

// Store & actions
import { resetSuccess } from "../../../../store/settings/actions";
import { fetchConnections, resetShouldClosePanel, deleteConnection } from "../../../../store/social/actions";

// Components & functions
import { ConfirmPanel } from "../../../reusable/confirmPanelComponent/ConfirmPanelComponent";
import { BreadCrumbComponent } from "../../../reusable/breadCrumbComponent/BreadCrumbComponent";
import { ConnectionStatusComponent } from "../../../reusable/connectionStatusComponent/ConnectionStatusComponent";
import { NewConnectionPanelComponent } from "./newConnectionPanelComponent/NewConnectionPanelComponent";
import { ConnectionDeniedPanelComponent } from "./connectionDeniedPanelComponent/ConnectionDeniedPanelComponent"
import { ConnectionDetailsPanelComponent } from "./connectionDetailsPanelComponent/ConnectionDetailsPanelComponent";
import { PrivacyPolicyPanelComponent } from "./privacyPolicyPanelComponent/PrivacyPolicyPanelComponent";
import { getParameterByName } from "../../../../common/helperFunctions/UrlFunctions";
import { Formik } from "formik";
import { hasAccess, PermissionCodes } from "@one/core";
import { fetchApplications } from "../../../../store/tenant/actions";
import { createConnection, fetchConnectionByName, updateConnectionById } from "../../../../store/connection/actions";
import { IUpdateConnection } from "../../../../models/responseModels/connection/IUpdateConnection";
import { IUpdateConnectionValue } from "../../../../models/responseModels/connection/IUpdateConnectionValue";
import { IAddConnectionValue } from "../../../../models/responseModels/connection/IAddConnectionValue";
import { IAddConnection } from "../../../../models/responseModels/connection/IAddConnection";
import { ConnectionType } from "../../../../models/enums/ConnectionType";

const YouSettings = (props: WrappedComponentProps & RouteComponentProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	const settingsSlice = useSelector((state: IApplicationState) => state.settingsSlice);
	const socialSlice = useSelector((state: IApplicationState) => state.socialSlice);
	const currentUserSlice = useSelector((state: IApplicationState) => state.currentUserSlice);
	const connectionSlice = useSelector((state: IApplicationState) => state.connectionSlice);

	const [hasCorrelationId, setHasCorrelationId] = useState(false);

	const [hasQueryParamCode, setHasQueryParamCode] = useState(false);
	const [hasQueryParamErrorReason, setHasQueryParamErrorReason] = useState(false);
	const [QueryParamErrorDescription, setQueryParamErrorDescription] = useState<string | null>(null);
	const [reopenInfoBarIsOpen, setReopenInfoBarIsOpen] = useState(false);
	const [newConnectionPanelIsOpen, setNewConnectionPanelIsOpen] = useState(false);
	const [errorPanelIsOpen, setErrorPanelIsOpen] = useState(false);
	const [deleteConnectionPanelIsOpenFor, setDeleteConnectionPanelIsOpenFor] = useState<ISocialConnectionResponse | null>(null);
	const [connectionsDetailsPanelIsOpenFor, setConnectionsDetailsPanelIsOpenFor] = useState<ISocialConnectionResponse | null>(null);
	const [firstRenderOccured, setFirstRenderOccured] = useState(false);
	const [userHasAccess, setUserHasAccess] = React.useState(false);
	const [privacyPanelIsOpen, setPrivacyPanelIsOpen] = React.useState(false);

	React.useEffect(() => {
		setUserHasAccess(hasAccess(currentUserSlice.user as any, [PermissionCodes.ManageApplicationSettings]));
	}, [currentUserSlice.user]);

	useEffect(() => {
		dispatch(fetchApplications());
		dispatch(fetchConnectionByName("Topdesk"));
	}, [dispatch]);

	// Clear the success message if this still exists
	useEffect(() => {
		if (!firstRenderOccured) {
			setFirstRenderOccured(true);

			if (socialSlice.success.type !== SuccessTypes.None) {
				dispatch(resetSuccess());
			}
		}
	}, [dispatch, firstRenderOccured, socialSlice.success.type]);

	const columns: IColumn[] = [
		{
			key: "name",
			name: intl.formatMessage({
				id: "settings.you.insta.list.name",
				defaultMessage: "Weergavenaam",
			}),
			fieldName: "name",
			minWidth: 150,
			maxWidth: 150,
			isResizable: false,
		},
		{
			key: "status",
			name: intl.formatMessage({
				id: "settings.you.insta.list.status",
				defaultMessage: "Status",
			}),
			fieldName: "status",
			minWidth: 125,
			maxWidth: 125,
			isResizable: false,
		},
		{
			key: "actions",
			name: intl.formatMessage({ id: "general.actions", defaultMessage: "Acties" }),
			fieldName: "actions",
			minWidth: 75,
			maxWidth: 75,
			isResizable: false,
		},
	];

	// Used for breadcrumbs
	const pathToPage = [
		{
			text: intl.formatMessage({
				id: "settings.general",
				defaultMessage: "Instellingen",
			}),
			key: "settings",
		},
		{
			text: intl.formatMessage({
				id: "settings.card.you.title",
				defaultMessage: "One for You instellingen",
			}),
			key: "settings/youSettings",
		},
	];

	// try to get the Instagram access token from the URL.
	const queryParamCode = getParameterByName("code", window.location.search);
	if (queryParamCode) {
		if (!hasQueryParamCode) {
			setNewConnectionPanelIsOpen(true);
			setHasQueryParamCode(true);
		}
	}

	const queryParamErrorReason = getParameterByName("error_reason", window.location.search);
	if (queryParamErrorReason) {
		if (!hasQueryParamErrorReason) {
			setErrorPanelIsOpen(true)
			setHasQueryParamErrorReason(true);
		}
	}

	const queryParamErrorDescription = getParameterByName("error_description", window.location.search);
	if (queryParamErrorDescription) {
		if (!QueryParamErrorDescription) {
			setQueryParamErrorDescription(queryParamErrorDescription);
		}
	}

	// try to get the correlationId from the URL.
	const correlationId = getParameterByName("confirmationCode", window.location.search);
	if (correlationId) {
		if (!hasCorrelationId && socialSlice.connections.length > 0) {
			const matchingConnection = socialSlice.connections.filter((c) => c.correlationId === correlationId)[0];
			setHasCorrelationId(true);
			if (matchingConnection) {
				setConnectionsDetailsPanelIsOpenFor(matchingConnection);
			}
		}
	}

	// Close any panels if they should and they are open.
	if (socialSlice.shouldClosePanel) {
		if (newConnectionPanelIsOpen) setNewConnectionPanelIsOpen(false);
		if (deleteConnectionPanelIsOpenFor !== null) setDeleteConnectionPanelIsOpenFor(null);
		if (queryParamCode) {
			setHasQueryParamCode(false);
			props.history.push(window.location.pathname);
		}

		dispatch(resetShouldClosePanel());
	}

	useEffect(() => {
		if (socialSlice.connections.length === 0) {
			dispatch(fetchConnections());
		}
	}, [socialSlice.connections.length, dispatch]);

	// Add custom rendering for specific columns
	const _renderItemColumn = (connection: ISocialConnectionResponse, index: number | undefined, column: IColumn | undefined) => {
		if (column === undefined) {
			return null;
		}
		const fieldContent = connection[column.fieldName as keyof ISocialConnectionResponse] as string;

		switch (column.key) {
			case "status":
				return (
					<div className={"column-field"}>
						<ConnectionStatusComponent connection={connection} />
					</div>
				);
			case "actions":
				return (
					<div className="column-field column-field__action">
						<span
							onClick={(e) => {
								e.stopPropagation();
								setDeleteConnectionPanelIsOpenFor(connection);
							}}
						>
							{intl.formatMessage({
								id: "general.delete",
								defaultMessage: "Verwijder",
							})}
						</span>
					</div>
				);
			default:
				return (
					<div className="column-field">
						<span>{fieldContent || "-"}</span>
					</div>
				);
		}
	};

	// returns the row with additional styling
	const _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
		const customStyles: Partial<IDetailsRowStyles> = {};
		if (props) {
			return (
				<div className={"youSettings-connection-list-row"} onClick={() => setConnectionsDetailsPanelIsOpenFor(props.item)}>
					<DetailsRow {...props} styles={customStyles} />
				</div>
			);
		}
		return null;
	};

	/*
		Displays a success message if there is one.
		If not, and the user just closed the 'create new connection' panel, ask the user if they want to reopen this and continue the process of adding a connection
		using a info MessageBar.
	*/
	const renderInstagramMessageBarUi = () => {
		if (reopenInfoBarIsOpen && socialSlice.success.type === SuccessTypes.None) {
			return (
				<div className={"settings-message-bar-container"}>
					<MessageBar
						messageBarType={MessageBarType.info}
						isMultiline={false}
						actions={
							<>
								<MessageBarButton
									onClick={() => {
										setReopenInfoBarIsOpen(false);
										props.history.push(window.location.pathname);
									}}
								>
									<FormattedMessage id="general.close" defaultMessage="Sluit" />
								</MessageBarButton>
								<MessageBarButton onClick={() => setNewConnectionPanelIsOpen(true)} primary style={{ marginRight: "5px" }}>
									<FormattedMessage id="settings.you.insta.reopen.panel.button" defaultMessage="Heropen" />
								</MessageBarButton>
							</>
						}
					>
						<FormattedMessage id="settings.you.insta.reopen.panel" defaultMessage="Per ongeluk het paneel gesloten?" />
					</MessageBar>
				</div>
			);
		}
		if (socialSlice.success.type !== SuccessTypes.None) {
			let successMessage =
				socialSlice.success.type === SuccessTypes.OnCreate ? (
					<FormattedMessage id="settings.you.insta.add.success" defaultMessage="Connectie succesvol toegevoegd." />
				) : (
					<FormattedMessage id="settings.you.insta.delete.success" defaultMessage="Connectie succesvol verwijderd." />
				);

			return (
				<div className={"settings-message-bar-container"}>
					<MessageBar messageBarType={MessageBarType.success} onDismiss={() => dispatch(resetSuccess())}>
						{successMessage}
					</MessageBar>
				</div>
			);
		}
	};

	const openPrivacyPanel = () => {
		setPrivacyPanelIsOpen(true);
	};

	const renderTopdeskMessageBarUi = () => {
		if (connectionSlice.success.type !== SuccessTypes.None || connectionSlice.error.type !== ErrorTypes.None) {
			let messageBarType = connectionSlice.success.type !== SuccessTypes.None ? MessageBarType.success : MessageBarType.error;
			let successMessage =
				connectionSlice.success.type === SuccessTypes.OnUpdate || connectionSlice.success.type === SuccessTypes.OnCreate ? (
					<FormattedMessage id="settings.you.topdesk.update.success" defaultMessage="Gegevens zijn successvol bijgewerkt." />
				) : (
					<FormattedMessage
						id="settings.you.topdesk.update.error"
						defaultMessage="Er is iets misgegaan bij het opslaan van de gegevens."
					/>
				);

			return (
				<div className={"settings-message-bar-container"}>
					<MessageBar messageBarType={messageBarType} onDismiss={() => dispatch(resetSuccess())}>
						{successMessage}
					</MessageBar>
				</div>
			);
		}
	};

	const renderInstagramContentUi = () => {
		if (settingsSlice.isLoading) {
			return (
				<div className="loaderWrapper">
					<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
				</div>
			);
		}

		// Build the link to the authorize
		const client_id = process.env.REACT_APP_ONE_FACEBOOK_APP_ID;
		const redirect_uri = window.location.origin + window.location.pathname;
		const link = `https://api.instagram.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&scope=user_profile,user_media&response_type=code`;

		return (
			<div className="youSettings">
				<div className="youSettings-instagram-logo">
					<img src={instagramImage} alt="Instagram logo" />
					<h1>
						<FormattedMessage id="settings.you.insta.title" defaultMessage="Instagram Connecties" />
					</h1>
				</div>

				{socialSlice.connections.length === 0 && (
					<div className="youSettings-no-connections">
						<Icon iconName="PlugDisconnected" />
						<FormattedMessage id="settings.you.insta.list.noConnections" defaultMessage="Geen connecties gevonden" />
					</div>
				)}
				{socialSlice.connections.length > 0 && (
					<DetailsList
						items={socialSlice.connections}
						onRenderItemColumn={_renderItemColumn}
						onRenderRow={_onRenderRow}
						columns={columns}
						selectionMode={SelectionMode.none}
						className="youSettings-connection-list"
					/>
				)}

				<PrimaryButton
					iconProps={{ iconName: "Add" }}
					text={intl.formatMessage({
						id: "settings.you.insta.add",
						defaultMessage: "Toevoegen",
					})}
					onClick={openPrivacyPanel}
				/>

				<PrivacyPolicyPanelComponent
					isOpen={privacyPanelIsOpen}
					redirectionLink={link}
					onDismiss={() => {
						setPrivacyPanelIsOpen(false);
					}}
					OpenErrorPanel={() => {
						setQueryParamErrorDescription("Je moet de privacy statement accepteren om door te kunnen gaan");
						setErrorPanelIsOpen(true);
					}}
				/>
			</div>
		);
	};

	const renderTopdeskContentUi = () => {
		if (!userHasAccess) {
			return;
		}
		if (settingsSlice.isLoading) {
			return (
				<div className="loaderWrapper">
					<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
				</div>
			);
		}

		let initialValues = {
			TopdeskApiUrl: "",
			TopdeskApiUsername: "",
			TopdeskApiPassword: "",
		};

		let connections = connectionSlice.connections.data.filter((d) => d.name === "Topdesk");
		if (connections && connections.length > 0) {
			let connection = connections[0];

			if (connection.values && connection.values.length > 0) {
				let apiUrl =
					connection.values.filter((c) => c.key === "apiUrl").length > 0
						? connection.values.filter((c) => c.key === "apiUrl")[0].value
						: "";

				let username =
					connection.values.filter((c) => c.key === "username").length > 0
						? connection.values.filter((c) => c.key === "username")[0].value
						: "";

				let password =
					connection.values.filter((c) => c.key === "password").length > 0
						? connection.values.filter((c) => c.key === "password")[0].value
						: "";

				initialValues = {
					TopdeskApiUrl: apiUrl !== null ? apiUrl : "",
					TopdeskApiUsername: username !== null ? username : "",
					TopdeskApiPassword: password !== null ? password : "",
				};
			}
		}

		return (
			<div className="youSettings">
				<div className="youSettings-topdesk-logo">
					<img src={topdeskImage} alt="Topdesk logo" />
					<h1>
						<FormattedMessage id="settings.you.topdesk.title" defaultMessage="Topdesk connecties" />
					</h1>
				</div>

				<Formik
					initialValues={initialValues}
					enableReinitialize
					onSubmit={(values) => {
						let connections = connectionSlice.connections.data.filter((d) => d.name === "Topdesk");
						let connectionExists = connections.length > 0 ? true : false;
						if (connectionExists) {
							//update
							let updateValues: IUpdateConnectionValue[] = [
								{
									key: "apiUrl",
									value: values.TopdeskApiUrl,
								},
								{
									key: "username",
									value: values.TopdeskApiUsername,
								},
								{
									key: "password",
									value: values.TopdeskApiPassword,
								},
							];

							let updateConnection: IUpdateConnection = {
								id: connections[0].id,
								values: updateValues,
							};
							dispatch(updateConnectionById(updateConnection));
						} else {
							//create
							//update
							let createValues: IAddConnectionValue[] = [
								{
									key: "apiUrl",
									value: values.TopdeskApiUrl,
								},
								{
									key: "username",
									value: values.TopdeskApiUsername,
								},
								{
									key: "password",
									value: values.TopdeskApiPassword,
								},
							];

							let createCon: IAddConnection = {
								name: "Topdesk",
								connectionTypeId: ConnectionType.BasicAuth,
								values: createValues,
							};
							dispatch(createConnection(createCon));
						}
					}}
				>
					{(props) => {
						const { values, errors, isSubmitting, setSubmitting, handleSubmit, handleChange, handleBlur } = props;
						if (
							isSubmitting &&
							(connectionSlice.success.type !== SuccessTypes.None || connectionSlice.error.type !== ErrorTypes.None)
						) {
							setSubmitting(false);
						}
						return (
							<div className="ilx-form-wrapper">
								<form onSubmit={handleSubmit}>
									<TextField
										id="TopdeskApiUrl"
										label={intl.formatMessage({
											id: "settings.you.topdesk.api.url",
											defaultMessage: "API URL",
										})}
										placeholder={"https://"}
										type="text"
										errorMessage={errors.TopdeskApiUrl}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.TopdeskApiUrl}
										className="ilx-text-field-small"
										autoComplete="Off"
									/>

									<TextField
										id="TopdeskApiUsername"
										label={intl.formatMessage({
											id: "settings.you.topdesk.api.username",
											defaultMessage: "API Username",
										})}
										type="text"
										errorMessage={errors.TopdeskApiUsername}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.TopdeskApiUsername}
										className="ilx-text-field-small"
										autoComplete="Off"
									/>

									<TextField
										id="TopdeskApiPassword"
										label={intl.formatMessage({
											id: "settings.you.topdesk.api.password",
											defaultMessage: "API Password",
										})}
										type="password"
										errorMessage={errors.TopdeskApiPassword}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.TopdeskApiPassword}
										className="ilx-text-field-small"
										autoComplete="Off"
									/>

									<PrimaryButton type="submit" disabled={isSubmitting}>
										<FormattedMessage id="general.save" defaultMessage="Opslaan" />
									</PrimaryButton>
								</form>
							</div>
						);
					}}
				</Formik>
			</div>
		);
	};

	const instagramMessageBarUi = renderInstagramMessageBarUi();
	const instagramSettingsTileBody = renderInstagramContentUi();
	const topdeskSettingsTileBody = renderTopdeskContentUi();
	const topdeskMessageBarUi = renderTopdeskMessageBarUi();

	return (
		<div>
			<BreadCrumbComponent path={pathToPage} />

			{instagramMessageBarUi}
			{topdeskMessageBarUi}

			<section className="setting-pages-wrapper">
				<div className="settings-setting-wrapper" test-name="settings-tile">
					<div className="settings-tile-body form-wrapper">{instagramSettingsTileBody}</div>
				</div>

				<div className="settings-setting-wrapper" test-name="settings-tile">
					<div className="settings-tile-body form-wrapper">{topdeskSettingsTileBody}</div>
				</div>
			</section>

			<NewConnectionPanelComponent
				isOpen={newConnectionPanelIsOpen}
				code={queryParamCode}
				onDismiss={() => {
					setReopenInfoBarIsOpen(true);
					setNewConnectionPanelIsOpen(false);
				}}
			/>

			<ConnectionDeniedPanelComponent
				isOpen={errorPanelIsOpen}
				errorDescription={QueryParamErrorDescription}
				onDismiss={() => {
					setErrorPanelIsOpen(false)
				}}
			/>

			<ConnectionDetailsPanelComponent
				isOpen={connectionsDetailsPanelIsOpenFor !== null}
				connection={connectionsDetailsPanelIsOpenFor}
				correlationId={correlationId}
				onDismiss={() => {
					setConnectionsDetailsPanelIsOpenFor(null);
				}}
			/>
			{deleteConnectionPanelIsOpenFor !== null && (
				<ConfirmPanel
					isOpen={deleteConnectionPanelIsOpenFor !== null}
					headerText={intl.formatMessage({
						id: "settings.you.insta.delete.confirm.header",
						defaultMessage: "Verwijder connectie",
					})}
					contentDescription={intl.formatMessage({
						id: "settings.you.insta.delete.confirm.description",
						defaultMessage: "Weet u zeker dat u de deze connectie wilt verwijderen?",
					})}
					contentTitle={deleteConnectionPanelIsOpenFor.name}
					onCancel={() => setDeleteConnectionPanelIsOpenFor(null)}
					onConfirm={() => dispatch(deleteConnection(deleteConnectionPanelIsOpenFor))}
					errorMessage={
						socialSlice.error.type === ErrorTypes.OnDelete
							? intl.formatMessage({
									id: "settings.you.insta.delete.confirm.error",
									defaultMessage: "Er ging iets mis bij het verwijderen van deze connectie.",
							  })
							: undefined
					}
				/>
			)}
		</div>
	);
};

export const YouSettingsComponent = withRouter(injectIntl(YouSettings));
