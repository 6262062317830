import * as React from "react";
import { ISocialConnectionResponse } from "../../../models/responseModels/social/ISocialConnectionResponse";
import { SocialConnectionStatus } from "../../../models/enums/SocialConnectionStatus";
import { WrappedComponentProps, injectIntl } from "react-intl";

export interface IConnectionStatusComponent {
	connection: ISocialConnectionResponse;
}

// Renders the status with specific styling based on the value.
const ConnectionStatus = (props: WrappedComponentProps & IConnectionStatusComponent) => {
	const { intl, connection } = props;
	let statusText = "";
	let columnClass = "";

	switch (connection.status) {
		case SocialConnectionStatus.Requested:
			statusText = intl.formatMessage({
				id: "enum.socialConnectionStatus.requested",
				defaultMessage: "Requested",
			});
			break;
		case SocialConnectionStatus.Active:
			statusText = intl.formatMessage({ id: "enum.socialConnectionStatus.active", defaultMessage: "Active" });
			columnClass = "green-color";
			break;
		case SocialConnectionStatus.Deauthorized:
			statusText = intl.formatMessage({
				id: "enum.socialConnectionStatus.deauthorized",
				defaultMessage: "Deauthorized",
			});
			columnClass = "orange-color";
			break;
		case SocialConnectionStatus.Expired:
			statusText = intl.formatMessage({
				id: "enum.socialConnectionStatus.expired",
				defaultMessage: "Expired",
			});
			columnClass = "orange-color";
			break;
		case SocialConnectionStatus.Deleted:
			statusText = intl.formatMessage({
				id: "enum.socialConnectionStatus.deleted",
				defaultMessage: "Deleted",
			});
			columnClass = "red-color";
			break;
		default:
			statusText = connection.statusText;
	}

	return <span className={columnClass}>{statusText}</span>;
};

export const ConnectionStatusComponent = injectIntl(ConnectionStatus);
