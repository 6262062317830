import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IntlProvider, FormattedMessage } from "react-intl";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faUser, faBell } from "@fortawesome/free-solid-svg-icons";
import Loader from "react-loader-spinner";

// import "./styles/themes/default.scss";

import { acquireToken, acquireGraphToken } from "./adalConfig";

import { IApplicationState } from "./models/interfaces/IApplicationState";

import { fetchCurrentUser } from "./store/currentUser/actions";
import { fetchSettings } from "./store/settings/actions";

import { translationSets } from "./assets/translations";
import { decideLanguageToUse } from "./common/helperFunctions/LanguageHelpers";

import { NavBar } from "./components/shellComponents/navBar/NavBar";
import { PageContainer } from "./components/shellComponents/pageContainer/PageContainer";
import { CheckTenantSubscriptionStatus } from "./common/helperFunctions/SubscriptionHelpers";

import moment from "moment";
import "moment/locale/nl";
import { SubscriptionStatus } from "@one/core";
import { initializeIcons } from "@fluentui/react";
import { OneThemeProvider, OneThemes } from "./styles/themes/OneThemeProvider";

//@ts-ignore
library.add(faBars, faUser, faBell);

const App = () => {
	initializeIcons();
	const dispatch = useDispatch();
	const settings = useSelector((state: IApplicationState) => state.settingsSlice);

	const [tenantSubscriptionStatus, setTenantSubscriptionStatus] = useState(SubscriptionStatus.None);
	const [isCheckingSubscriptionStatus, setIsCheckingSubscriptionStatus] = useState(true);
	const localeSetting = decideLanguageToUse(settings);
	moment.locale(localeSetting);

	useEffect(() => {
		const checkTenantHasAcces = async () => {
			setTenantSubscriptionStatus(await CheckTenantSubscriptionStatus());
			setIsCheckingSubscriptionStatus(false);
		};

		dispatch(fetchCurrentUser());
		dispatch(fetchSettings());
		checkTenantHasAcces();
	}, [dispatch]);

	// init token refresher
	const appTokenRefresher = () => {
		acquireToken();
		acquireGraphToken();
	};

	// Sets an interval that refreshes to get the token every 15 minutes
	// We use this because our custom API's do not automatically issue a
	// refresh token.
	setInterval(appTokenRefresher, 90000);

	let appContent: JSX.Element | null = null;
	if (isCheckingSubscriptionStatus) {
		appContent = (
			<div className="app-centered">
				<div className="loaderWrapper">
					<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
				</div>
			</div>
		);
	} else if (tenantSubscriptionStatus === SubscriptionStatus.Active) {
		appContent = (
			<div className="app">
				<React.Fragment>
					<NavBar />
					<PageContainer />
				</React.Fragment>
			</div>
		);
	} else if (tenantSubscriptionStatus === SubscriptionStatus.Inactive) {
		appContent = (
			<div className="app-centered">
				<h1>
					<FormattedMessage id="subscription.inactive" defaultMessage="Uw abonnement is verlopen." />
				</h1>
			</div>
		);
	} else if (tenantSubscriptionStatus === SubscriptionStatus.None) {
		appContent = (
			<div className="app-centered">
				<h1>
					<FormattedMessage id="subscription.none" defaultMessage="U heeft geen abonnement voor One." />
				</h1>
			</div>
		);
	} else if (tenantSubscriptionStatus === SubscriptionStatus.Error) {
		appContent = (
			<div className="app-centered">
				<h1>
					<FormattedMessage id="subscription.error" defaultMessage="Er is iets fout gegaan bij het ophalen van uw abonnement." />
				</h1>
			</div>
		);
	}

	return (
		<IntlProvider locale={localeSetting} messages={translationSets[localeSetting]}>
			<OneThemeProvider theme={OneThemes.Default}>{appContent}</OneThemeProvider>
		</IntlProvider>
	);
};

export default App;
