import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Panel, PanelType, Icon, PrimaryButton, Separator } from "@fluentui/react";
import Loader from "react-loader-spinner";

import { fetchAllRoles, addUserRole } from "../../../../../../store/users/actions";
import { IApplicationState } from "../../../../../../models/interfaces/IApplicationState";
import { ErrorTypes } from "../../../../../../models/enums/ErrorTypes";
import { RoleResponse } from "../../../../../../models/responseModels/user/RoleResponse";
import { AlertComponent } from "../../../../../reusable/alertComponent/AlertComponent";
import { sortArray } from "../../../../../../common/helperFunctions/ObjectsAndArrayHelpers";
import { IRole, RoleCodes } from "@one/core";

export interface AddRolePanelProps {
	userId: number;
	isOpen: boolean;
	onClose: () => void;
	rolesToFilterOut: IRole[];
}

export const AddRolesPanelComponent = (props: AddRolePanelProps) => {
	const { userId, isOpen, onClose, rolesToFilterOut } = props;
	const dispatch = useDispatch();
	const usersSlice = useSelector((state: IApplicationState) => state.usersSlice);

	useEffect(() => {
		if (isOpen && usersSlice.allRoles.length === 0) {
			dispatch(fetchAllRoles());
		}
	}, [isOpen, usersSlice.allRoles.length, dispatch]);

	if (!isOpen) {
		return null;
	}

	const determinePanelContent = () => {
		if (usersSlice.isFetchingAllRoles) {
			return (
				<div className="loaderWrapper">
					<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
				</div>
			);
		}

		const availableRoles = usersSlice.allRoles.filter((role) => {
			if (role.code === RoleCodes.None) {
				return false;
			}
			const results = rolesToFilterOut.filter((roleToFilterOut) => {
				return roleToFilterOut.id === role.id;
			});
			return results.length === 0;
		});

		let rolesUi: JSX.Element | JSX.Element[] = (
			<FormattedMessage
				id="users.modal.roles.add.noAvailableRoles"
				defaultMessage="Er zijn geen beschikbare rollen om toe te kennen."
			/>
		);

		if (availableRoles.length > 0) {
			rolesUi = availableRoles
				.sort(sortArray("name"))
				.reverse()
				.map((role) => {
					let roleUi = determineUiBasedOnRoleCode(role);

					return (
						<div className={"add-role-option-container"} key={role.id}>
							<div
								className={`add-role-option`}
								onClick={() => {
									dispatch(addUserRole(userId, role));
								}}
							>
								<div className="add-role-option-header">
									<h3>{role.name}</h3>
									<Icon iconName={roleUi.iconName} />
								</div>
								<span>{roleUi.description}</span>
							</div>

							<Separator />
						</div>
					);
				});
		}

		return (
			<div className="add-roles-container">
				<p>
					<FormattedMessage
						id="users.modal.roles.add.description"
						defaultMessage="Klik op een rol om deze toe te kennen aan de geselecteerde gebruiker."
					/>
				</p>
				<h3>
					<FormattedMessage id="users.modal.roles.add.availableRoles" defaultMessage="Beschikbare rollen" />:
				</h3>
				{usersSlice.isAddingUserRole && (
					<div className="loaderWrapper">
						<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
					</div>
				)}
				{!usersSlice.isAddingUserRole && <React.Fragment>{rolesUi}</React.Fragment>}
			</div>
		);
	};

	const panelContent = determinePanelContent();

	return (
		<Panel
			className="one-panel"
			isOpen={isOpen}
			type={PanelType.smallFixedFar}
			onDismiss={onClose}
			isLightDismiss
			onLightDismissClick={onClose}
			closeButtonAriaLabel="Close"
		>
			<section className="one-panel__panel-header">
				<div className="one-panel__panel-header--flex">
					<h1>
						<FormattedMessage id="users.modal.roles.addRoles" defaultMessage="Voeg rollen toe" />
					</h1>

					<div className="one-panel__panel-close" onClick={onClose}>
						<Icon iconName="ChromeClose" />
					</div>
				</div>
			</section>
			<section className="one-panel__panel-content">
				{usersSlice.error.type === ErrorTypes.OnMetaDataLoad ||
					(usersSlice.error.type === ErrorTypes.OnUpdate && <AlertComponent type="danger" message={usersSlice.error.message} />)}

				{panelContent}

				<PrimaryButton onClick={props.onClose}>
					<FormattedMessage id="general.done" defaultMessage="Klaar" />
				</PrimaryButton>
			</section>
		</Panel>
	);
};

const determineUiBasedOnRoleCode = (role: RoleResponse): { description: any; iconName: string } => {
	let result = {
		description: <></>,
		iconName: "",
	};

	switch (role.code) {
		case RoleCodes.AddressManagerRole:
			result.iconName = "PageList";
			result.description = (
				<FormattedMessage id={"users.modal.roles.address.text"} defaultMessage="Beheert de adressen binnen One for You." />
			);
			break;
		case RoleCodes.AdministratorRole:
			result.iconName = "Admin";
			result.description = (
				<FormattedMessage id={"users.modal.roles.admin.text"} defaultMessage="Heeft alle rechten binnen de One applicaties." />
			);
			break;
		case RoleCodes.AnnouncementManagerRole:
			result.iconName = "Megaphone";
			result.description = (
				<FormattedMessage id={"users.modal.roles.announcement.text"} defaultMessage="Beheert de mededelingen binnen One for You." />
			);
			break;
		case RoleCodes.EventManagerRole:
			result.iconName = "Calendar";
			result.description = (
				<FormattedMessage id={"users.modal.roles.event.text"} defaultMessage="Beheert de evenementen binnen One for Events." />
			);
			break;
		case RoleCodes.ShortcutManagerRole:
			result.iconName = "BulletedList2";
			result.description = (
				<FormattedMessage
					id={"users.modal.roles.shortcut.text"}
					defaultMessage="Beheert de gedeelde snelkoppelingen binnen One for You."
				/>
			);
			break;
		case RoleCodes.SocialManagerRole:
			result.iconName = "PlugConnected";
			result.description = (
				<FormattedMessage id={"users.modal.roles.social.text"} defaultMessage="Beheert de connecties met social media kanalen." />
			);
			break;
		case RoleCodes.TeamManagerRole:
			result.iconName = "TeamsLogo";
			result.description = (
				<FormattedMessage
					id={"users.modal.roles.team.text"}
					defaultMessage="Beheert de aanvragen voor een team die gebruikers indienen."
				/>
			);
			break;
		case RoleCodes.UsageReportingRole:
			result.iconName = "AnalyticsView";
			result.description = (
				<FormattedMessage
					id={"users.modal.roles.usage.text"}
					defaultMessage="Heeft het recht de gebruikersstatistieken van One for You in te zien."
				/>
			);
			break;
		case RoleCodes.NewsManagerRole:
			result.iconName = "News";
			result.description = (
				<FormattedMessage id={"users.modal.roles.news.text"} defaultMessage="Heeft het recht om nieuwsberichten te pinnen." />
			);
			break;
		case RoleCodes.UserRole:
			result.iconName = "People";
			result.description = (
				<FormattedMessage
					id={"users.modal.roles.user.text"}
					defaultMessage="Is een reguliere gebruiker en heeft geen speicale rechten."
				/>
			);
			break;
		case RoleCodes.VacancyManagerRole:
			result.iconName = "Work";
			result.description = (
				<FormattedMessage
					id={"users.modal.roles.vacancy.text"}
					defaultMessage="Beheert de (interne) vacatures binnen One for You."
				/>
			);
			break;
		case RoleCodes.StartupMessageManagerRole:
			result.iconName = "Chat";
			result.description = (
				<FormattedMessage
					id={"users.modal.roles.startup.text"}
					defaultMessage="Beheert de startup messages die getoond worden binnen One for You."
				/>
			);
			break;
		case RoleCodes.TaxonomyManager:
			result.iconName = "BulletedTreeList";
			result.description = (
				<FormattedMessage id={"users.modal.roles.taxonomy.text"} defaultMessage="Manages the taxonomy used within One." />
			);
			break;
		case "OneQualityManagement_Admin":
			result.iconName = "ActivateOrders";
			result.description = <FormattedMessage id={"users.modal.roles.quality.text"} />;
			break;
		case RoleCodes.OneForMediaManagerRole:
			result.iconName = "ImageSearch";
			result.description = <FormattedMessage id={"users.modal.roles.media.text"} />;
			break;
		case RoleCodes.ReportingPortalManagerRole:
			result.iconName = "PowerBILogo";
			result.description = <FormattedMessage id={"users.modal.roles.reporting.text"} />;
			break;
		case RoleCodes.InformationManager:
			result.description = <FormattedMessage id={"users.modal.roles.informationManager.text"} />;
			break;
	}

	return result;
};
