// Framework & Third-party
import * as React from "react";
import { Panel, Icon, PanelType } from "@fluentui/react";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";

export interface INewConnectionPanelProps {
	isOpen: boolean;
	errorDescription: string | null;
	onDismiss: () => void;
}

const ConnectionDeniedPanel = (props: WrappedComponentProps & INewConnectionPanelProps) => {
	const { errorDescription } = props;

	if (errorDescription === null) {
		return null;
	}

	return (
		<div>
			<Panel
				className="one-panel"
				isOpen={props.isOpen}
				type={PanelType.smallFixedFar}
				onDismiss={props.onDismiss}
				isLightDismiss
				onLightDismissClick={props.onDismiss}
				closeButtonAriaLabel="Close"
			>
				<section className="one-panel__panel-header">
					<div className="one-panel__panel-header--flex">
						<h1>
							<FormattedMessage
								id="settings.you.insta.details.panel.header"
								defaultMessage="Connectie details"
							/>
						</h1>
						<div className="one-panel__panel-close" onClick={props.onDismiss}>
							<Icon iconName="ChromeClose" />
						</div>
					</div>
				</section>
				<section className="one-panel__panel-content">
					<div className="readonly-field">
						<span className="readonly-field__label">
							<FormattedMessage
								id="settings.you.insta.details.panel.status.label"
								defaultMessage="Status"
							/>
						</span>
						<span className="readonly-field__value">Error</span>
					</div>
					<div className="readonly-field">
						<span className="readonly-field__label">
							<FormattedMessage
								id="settings.you.insta.details.panel.description.label"
								defaultMessage="Error Description"
							/>
						</span>
						<span className="readonly-field__value">{errorDescription}</span>
					</div>
				</section>
			</Panel>
		</div>
	);
};

export const ConnectionDeniedPanelComponent = injectIntl(ConnectionDeniedPanel);
