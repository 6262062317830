import { IErrorState } from "../../models/interfaces/IErrorState";
import { ISuccessState } from "../../models/interfaces/ISuccessState";
import { ISocialConnectionResponse } from "../../models/responseModels/social/ISocialConnectionResponse";

export enum SocialActionTypes {
	FETCH_CONNECTIONS_STARTED = "FETCH_CONNECTIONS_STARTED",
	FETCH_CONNECTIONS_SUCCESS = "FETCH_CONNECTIONS_SUCCESS",
	FETCH_CONNECTIONS_FAILURE = "FETCH_CONNECTIONS_FAILURE",

	ADD_CONNECTION_STARTED = "ADD_CONNECTION_STARTED",
	ADD_CONNECTION_SUCCESS = "ADD_CONNECTION_SUCCESS",
	ADD_CONNECTION_FAILURE = "ADD_CONNECTION_FAILURE",

	DELETE_CONNECTION_STARTED = "DELETE_CONNECTION_STARTED",
	DELETE_CONNECTION_SUCCESS = "DELETE_CONNECTION_SUCCESS",
	DELETE_CONNECTION_FAILURE = "DELETE_CONNECTION_FAILURE",

	RESET_SHOULD_CLOSE_PANEL = "RESET_SHOULD_CLOSE_PANEL",
	RESET_ERROR = "RESET_ERROR",
	RESET_SUCCESS = "RESET_SUCCESS",
}

export type SocialState = Readonly<{
	connections: ISocialConnectionResponse[];

	isLoading: boolean;
	isCreating: boolean;

	shouldClosePanel: boolean;

	success: ISuccessState;
	error: IErrorState;
}>;
