// Framework & Third-party
import * as React from "react";
import { useState, useEffect } from "react";
import { Panel, Icon, PanelType, MessageBar, MessageBarType } from "@fluentui/react";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import moment from "moment";

// Models
import { ISocialConnectionResponse } from "../../../../../models/responseModels/social/ISocialConnectionResponse";

// Component & helpers
import { stringHasValue } from "../../../../../common/helperFunctions/GeneralHelpers";
import { ConnectionStatusComponent } from "../../../../reusable/connectionStatusComponent/ConnectionStatusComponent";

export interface INewConnectionPanelProps {
	isOpen: boolean;
	connection: ISocialConnectionResponse | null;
	correlationId: string | null;
	onDismiss: () => void;
}

const ConnectionDetailsPanel = (props: WrappedComponentProps & INewConnectionPanelProps) => {
	const { connection, intl } = props;

	const [correlationIdMessage, setCorrelationIdMessage] = useState<string | null>(null);

	useEffect(() => {
		if (stringHasValue(props.correlationId)) {
			setCorrelationIdMessage(
				intl.formatMessage({
					id: "settings.you.insta.details.panel.correlation.message",
					defaultMessage:
						"Uw aanvraag is binnengekomen, volg de status hiervan in dit paneel.",
				})
			);
		}
	}, [props.correlationId, intl]);

	if (connection === null) {
		return null;
	}

	return (
		<div>
			<Panel
				className="one-panel"
				isOpen={props.isOpen}
				type={PanelType.smallFixedFar}
				onDismiss={props.onDismiss}
				isLightDismiss
				onLightDismissClick={props.onDismiss}
				closeButtonAriaLabel="Close"
			>
				<section className="one-panel__panel-header">
					<div className="one-panel__panel-header--flex">
						<h1>
							<FormattedMessage
								id="settings.you.insta.details.panel.header"
								defaultMessage="Connectie details"
							/>
						</h1>

						<div className="one-panel__panel-close" onClick={props.onDismiss}>
							<Icon iconName="ChromeClose" />
						</div>
					</div>
				</section>
				{correlationIdMessage && props.correlationId === props.connection?.correlationId && (
					<MessageBar
						messageBarType={MessageBarType.success}
						onDismiss={() => setCorrelationIdMessage(null)}
					>
						{correlationIdMessage}
					</MessageBar>
				)}
				<section className="one-panel__panel-content">
					<div className="readonly-field">
						<span className="readonly-field__label">
							<FormattedMessage
								id="settings.you.insta.details.panel.name.label"
								defaultMessage="Naam"
							/>
						</span>
						<span className="readonly-field__value">{connection.name}</span>
					</div>
					<div className="readonly-field">
						<span className="readonly-field__label">
							<FormattedMessage
								id="settings.you.insta.details.panel.status.label"
								defaultMessage="Status"
							/>
						</span>
						<span className="readonly-field__value">
							<ConnectionStatusComponent connection={connection} />
						</span>
					</div>
					{connection.expirationDate !== null && (
						<div className="readonly-field">
							<span className="readonly-field__label">
								<FormattedMessage
									id="settings.you.insta.details.panel.expirationDate.label"
									defaultMessage="Verloopdatum"
								/>
							</span>
							<span className="readonly-field__value">
								{moment(connection.expirationDate).format("LL")}
							</span>
						</div>
					)}
					{connection.lastSyncDate !== null && (
						<div className="readonly-field">
							<span className="readonly-field__label">
								<FormattedMessage
									id="settings.you.insta.details.panel.lastSyncDate.label"
									defaultMessage="Laatst bijgewerkt"
								/>
							</span>
							<span className="readonly-field__value">
								{moment(connection.lastSyncDate).format("LLL")}
							</span>
						</div>
					)}
				</section>
			</Panel>
		</div>
	);
};

export const ConnectionDetailsPanelComponent = injectIntl(ConnectionDetailsPanel);
