// Framework & Third-party
import * as React from "react";
import { Panel, Icon, PanelType, PrimaryButton, MessageBar, MessageBarType } from "@fluentui/react";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

// Models
import { SocialAccountTypes } from "../../../../../models/enums/SocialAccountTypes";
import { SuccessTypes, ErrorTypes } from "../../../../../models/enums";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";

// Stores & Actions
import { addConnection, resetError } from "../../../../../store/social/actions";
import { INewSocialConnection } from "../../../../../models/interfaces/INewSocialConnection";

export interface INewConnectionPanelProps {
	isOpen: boolean;
	code: string | null;
	onDismiss: () => void;
}

const NewConnectionPanel = (props: WrappedComponentProps & INewConnectionPanelProps) => {
	const { code, intl } = props;
	const dispatch = useDispatch();
	const socialSlice = useSelector((state: IApplicationState) => state.socialSlice);

	if (code === null) {
		return null;
	}

	return (
		<div>
			<Panel
				className="one-panel"
				isOpen={props.isOpen}
				type={PanelType.smallFixedFar}
				onDismiss={props.onDismiss}
				isLightDismiss
				onLightDismissClick={props.onDismiss}
				closeButtonAriaLabel="Close"
			>
				<section className="one-panel__panel-header">
					<div className="one-panel__panel-header--flex">
						<h1>
							<FormattedMessage
								id="settings.you.insta.add.panel.header"
								defaultMessage="Nieuwe connectie"
							/>
						</h1>

						<div className="one-panel__panel-close" onClick={props.onDismiss}>
							<Icon iconName="ChromeClose" />
						</div>
					</div>
				</section>
				{socialSlice.error.type === ErrorTypes.OnCreate && (
					<MessageBar
						messageBarType={MessageBarType.error}
						onDismiss={() => dispatch(resetError())}
					>
						<FormattedMessage
							id="settings.you.insta.add.panel.error"
							defaultMessage="Er is iets misgegaan. Mogelijk is deze code al gebruikt, probeer het opnieuw."
						/>
					</MessageBar>
				)}
				<section className="one-panel__panel-content">
					<div className="form-wrapper">
						<Formik
							initialValues={{
								name: "",
								accountType: SocialAccountTypes.Instagram,
								authorizationCode: code,
							}}
							onSubmit={(values) => {
								const newConnection: INewSocialConnection = {
									accountType: values.accountType,
									authorizationCode: values.authorizationCode,
									name: values.name,
									redirectUri: window.location.origin + window.location.pathname,
								};
								dispatch(addConnection(newConnection));
							}}
							validationSchema={Yup.object().shape({
								name: Yup.string().required(
									intl.formatMessage({
										id: "general.validation.required",
										defaultMessage: "Dit veld is verplicht.",
									})
								),
							})}
						>
							{(props) => {
								const {
									values,
									touched,
									errors,
									isSubmitting,
									setSubmitting,
									handleSubmit,
									handleChange,
									handleBlur,
								} = props;
								if (
									isSubmitting &&
									(socialSlice.success.type !== SuccessTypes.None ||
										socialSlice.error.type !== ErrorTypes.None)
								) {
									setSubmitting(false);
								}
								return (
									<form onSubmit={handleSubmit}>
										<label>
											<span>
												<FormattedMessage
													id="settings.you.insta.add.panel.name.label"
													defaultMessage="Naam"
												/>
											</span>
										</label>
										{errors.name && touched.name && (
											<div className="input-feedback">{errors.name}</div>
										)}
										<input
											id="name"
											type="text"
											onChange={handleChange}
											placeholder={intl.formatMessage({
												id: "settings.you.insta.add.panel.name.placeholder",
												defaultMessage: "Voer een weergavenaam in",
											})}
											onBlur={handleBlur}
											value={values.name}
											className={errors.name && touched.name ? "error" : ""}
										/>

										<PrimaryButton type="submit" disabled={isSubmitting}>
											<FormattedMessage
												id="general.save"
												defaultMessage="Opslaan"
											/>
										</PrimaryButton>
									</form>
								);
							}}
						</Formik>
					</div>
				</section>
			</Panel>
		</div>
	);
};

export const NewConnectionPanelComponent = injectIntl(NewConnectionPanel);
