import { combineReducers, createStore, applyMiddleware } from "redux";
import ThunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory, History } from "history";

import { currentUserReducer } from "./currentUser/reducer";
import { connectRouter } from "connected-react-router";
import { IApplicationState } from "../models/interfaces/IApplicationState";
import { settingsReducer } from "./settings/reducer";
import { lookupListsReducer } from "./lookupLists/reducer";
import { usersReducer } from "./users/reducer";
import { tenantReducer } from "./tenant/reducer";
import { analyticsReducer } from "./analytics/reducer";
import { socialReducer } from "./social/reducer";
import { releasesReducer } from "./releases/reducer";
import { feedbackReducer } from "./feedback/reducer";
import { applicationsReducer } from "./applications/reducer";
import { audiencesReducer } from "./audiences/reducer";
import { taxonomyReducer } from "./taxonomy/reducer";
import { jobsReducer } from "./jobs/reducer";
import { mailTemplatesReducer } from "./mailTemplates/reducer";
import { teamTypesReducer } from "./teamTypes/reducer";
import { rolesReducer } from "./roles/reducer";
import { connectionReducer } from "./connection/reducer";

export const history = createBrowserHistory();

// This has to be before the 'combineReducers' function runs.
// Therefore this can not be placed in the indexApp.tsx
if (window.location.pathname === "/") {
	history.push("/home");
}

const rootReducer = (history: History<unknown>) =>
	combineReducers<IApplicationState>({
		router: connectRouter(history),
		currentUserSlice: currentUserReducer,
		settingsSlice: settingsReducer,
		lookupListsSlice: lookupListsReducer,
		usersSlice: usersReducer,
		tenantSlice: tenantReducer,
		analyticsSlice: analyticsReducer,
		socialSlice: socialReducer,
		releasesSlice: releasesReducer,
		applicationsSlice: applicationsReducer,
		audiencesSlice: audiencesReducer,
		taxonomySlice: taxonomyReducer,
		jobsSlice: jobsReducer,
		mailTemplatesSlice: mailTemplatesReducer,
		teamTypesSlice: teamTypesReducer,
		feedbackSlice: feedbackReducer,
		rolesSlice: rolesReducer,
		connectionSlice: connectionReducer,
	});

export default function configureStore() {
	const middleware = [ThunkMiddleware];
	const middleWareEnhancer = applyMiddleware(...middleware);

	return createStore(rootReducer(history), composeWithDevTools(middleWareEnhancer));
}
