import React from "react";
import { CommandBar } from "one-ui-fabric/dist";

const DefaultCommandBar = props => {
	return (
		<div className="navbar-auxiliary-row">
			<CommandBar items={props.items} />
		</div>
	);
};

export default DefaultCommandBar;
